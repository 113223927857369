import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { styled, useTheme } from "@mui/material";

import VerifiedIcon from "@mui/icons-material/Verified";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import React from "react";
import { useTranslation } from "react-i18next";
import useIsSmartDossierPaid from "../../hooks/transaction/useIsSmartDossierPaid";
import { useCheckDossier } from "../../hooks/profile/useCheckDossier";
import { useStateValue } from "../../store";

const PaymentBox = styled(Stack, {
    shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }) => ({
    backgroundColor: selected
        ? theme.palette.secondary.main
        : theme.palette.grey[200],
    padding: "15px 20px",
    borderRadius: 30,
    // rowGap: 20,
    justifyContent: "space-between",
    minHeight: 480,
    height: "100%",
}));

const CustomBadge = styled(Chip)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
}));

const ShortText = styled(Typography, {
    shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }) => ({
    color: selected ? theme.palette.common.white : theme.palette.primary.dark,
    fontWeight: 400,
}));

const PriceText = styled(Typography, {
    shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }) => ({
    color: selected ? theme.palette.common.white : theme.palette.secondary.main,
}));
const StrikedText = styled("s", {
    shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }) => ({
    color: selected ? theme.palette.common.white : theme.palette.secondary.main,
}));

const BenefitLists = styled("ul", {
    shouldForwardProp: (props) => props !== "selected",
})(({ theme, selected }) => ({
    paddingLeft: theme.spacing(4),
    // paddingRight: theme.spacing(4),
    color: selected ? theme.palette.common.white : theme.palette.primary.dark,
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px",
}));

function PaymentOptionCardv2({
    selected,
    badgeLabel,
    shortText,
    benefitList,
    onChoose,
    price,
    value,
    paymentSlug,
    loadingOnClick,
}) {
    const { t } = useTranslation();
    const [{ user_id }] = useStateValue();
    const theme = useTheme();

    const {
        isLoading: isLoadingPaymentStatus,
        data: paymentStatusData,
        status: getPaymentDataStatus,
    } = useIsSmartDossierPaid(user_id);

    let isPayment = paymentStatusData?.dossierPayment;

    const currentPlanIcon = (
        <Stack direction="row" alignItems="center" spacing={1}>
            <CheckCircleIcon color="success" />
            <Typography
                variant="body2"
                sx={{
                    color: selected
                        ? theme.palette.common.white
                        : theme.palette.primary.main,
                    fontWeight: "bold",
                }}
            >
                {t("current_plan")}
            </Typography>
        </Stack>
    );
    const getPaymentMethod = () => {
        if (isPayment == 1 && paymentStatusData.paymentMethod === "payment")
            return "invest";

        // if (
        //     (getPaymentDataStatus === "success" &&
        //         paymentStatusData.paymentMethod === "sponsor") ||
        //     (getPaymentDataStatus === "success" &&
        //         paymentStatusData.paymentMethod === "free")
        // )
        //     return "free";

        if (isPayment == 1 && paymentStatusData.paymentMethod === "sponsor")
            return "sponsor";

        if (
            getPaymentDataStatus === "success" &&
            paymentStatusData.paymentMethod === "free"
        )
            return "free";

        return "";
    };

    let apiPaymentMethod = getPaymentMethod();

    const disablePaymentMethod = (method) => {
        if (isPayment == 0) return false;
        if (
            apiPaymentMethod === method ||
            (apiPaymentMethod === "invest" && method === "free") ||
            (apiPaymentMethod === "invest" && method === "sponsor")
        )
            return true;
    };

    return (
        <PaymentBox selected={selected}>
            <Stack>
                <Stack rowGap={2.5}>
                    <Stack
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                    >
                        <CustomBadge label={t(badgeLabel)} />
                        {apiPaymentMethod === value && currentPlanIcon}
                    </Stack>
                    <ShortText variant="body2" selected={selected}>
                        {t(shortText)}
                    </ShortText>
                    <BenefitLists selected={selected}>
                        {benefitList?.map((benefit, index) => (
                            <li key={index}>{t(benefit)}</li>
                        ))}
                    </BenefitLists>
                </Stack>
            </Stack>
            <Stack rowGap={3.5} sx={{ mt: 4 }}>
                <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    columnGap={2.5}
                    // pt={5}
                >
                    <PriceText variant="h4" selected={selected}>
                        CHF {price}.-
                    </PriceText>
                    <StrikedText selected={selected}>
                        {t("instead_of_CHF_99")}
                    </StrikedText>
                </Stack>
                <Button
                    variant="contained"
                    disabled={
                        disablePaymentMethod(value)
                        // ||
                        // loadingOnClick(paymentSlug)
                    }
                    onClick={onChoose}
                >
                    {t("choose")}
                </Button>
            </Stack>
        </PaymentBox>
    );
}

export default PaymentOptionCardv2;
