import React, { forwardRef, useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import CardActionArea from "@mui/material/CardActionArea";
import Box from "@mui/material/Box";
import ReactPlayer from "react-player";
import { DownloadApps } from "../../utils/imgImports";
import { Icon } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material";

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

export function VideoModal({ video, open, handleClose, description, title }) {
    const theme = useTheme();

    return (
        <Dialog
            fullWidth
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="md"
            // fullScreen={isBelowSm}
            scroll="paper"
            PaperProps={{
                sx: {
                    borderRadius: 5,
                },
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    position: "absolute",
                    top: 12,
                    right: 12,
                }}
            >
                <CloseIcon
                    onClick={handleClose}
                    fontSize="medium"
                    sx={{
                        cursor: "pointer",
                        color: theme.palette.grey[500],
                    }}
                />
            </Box>
            <DialogTitle>{title || "Lorem ipsum dolor sit"}</DialogTitle>
            <DialogContent>
                <ReactPlayer url={video} className="video-guide" controls />
                <Typography gutterBottom variant="body2" mt={2}>
                    {description ||
                        "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit, officia?"}
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

export function VideoGuideCard({ img, onClick, description, title }) {
    return (
        <>
            <Box
                display={"flex"}
                flexDirection={"column"}
                rowGap={2}
                onClick={onClick}
            >
                <Box
                    sx={{
                        borderRadius: 4,
                        overflow: "hidden",
                        position: "relative",
                        cursor: "pointer",
                    }}
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            width: "100%",
                            background: " rgba(0, 0, 0, 0.36)",
                        }}
                    ></Box>

                    <img
                        src={img || DownloadApps}
                        alt="guide"
                        style={{
                            width: "100%",
                            height: 280,
                            objectFit: "cover",
                        }}
                    />
                    <Icon
                        // color="secondary"
                        sx={{
                            color: "#fff",
                            fontSize: "64px !important",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                        }}
                    >
                        play_circle
                    </Icon>
                </Box>
                <Box px={1}>
                    <Typography
                        gutterBottom
                        variant="h5"
                        sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {title ||
                            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit, officia?"}
                    </Typography>
                    <Typography
                        gutterBottom
                        variant="body2"
                        sx={{
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                        }}
                    >
                        {description ||
                            "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Velit, officia?"}
                    </Typography>
                </Box>
            </Box>
        </>
    );
}
