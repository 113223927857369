//* 05-09-24 closing chatGPT function and per the client requirement
export const handleNext =
    (
        methods,
        step,
        watchAnotherTenantMovingIn,
        setStep,
        // handleGPTAnswer,
        handleEditFormSubmit
    ) =>
    async () => {
        // console.log("🚀 ~ handleNext ~ step:", step);
        window.scrollTo({ top: 0, behavior: "smooth" });

        let allValues = methods.getValues();

        const isStepValid = await methods.trigger();

        if (isStepValid) {
            if ([1, 2, 3, 4, 5].includes(step)) {
                //onSubmitData(allValues);
                await handleEditFormSubmit(allValues);
            }

            // if (step > 1 && allValues?.description === "") {
            //     handleGPTAnswer();
            // }

            // console.log("step: " + step);
            //onSubmitData(allValue);
            if (
                (watchAnotherTenantMovingIn === "" ||
                    watchAnotherTenantMovingIn === "no") &&
                step === 0
            ) {
                return setStep(step + 2);
            }
            return setStep(step + 1);
        }
    };

export const handlePrev =
    (watchAnotherTenantMovingIn, step, setStep, setCurrentSection) => () => {
        window.scrollTo({ top: 0, behavior: "smooth" });

        if (step === 1 || step === 2) {
            // Set currentSection to 5 (last section in tenant 1 and 2)
            setCurrentSection(5);
        }

        setStep((prevState) => {
            if (
                (watchAnotherTenantMovingIn === "" ||
                    watchAnotherTenantMovingIn === "no") &&
                step === 2
            ) {
                return prevState - 2;
            }
            return prevState - 1;
        });
    };
