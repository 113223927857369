import { useState } from "react";

//*  Translation
import { useTranslation } from "react-i18next";

//* translation
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

//* store imports
import { useStateValue } from "../../store/StateProvider";

//* api calls
import { useCheckDossier } from "../../hooks/profile/useCheckDossier";
import useUserProfile from "../../hooks/profile/useUserProfile";

//* loading components
import Loading from "../../component/loading/Loading";

//* components import
import DashboardHeader from "../../component/dashboard/DashboardHeader";

//* image imports
// import { NextFlatLogo } from "../../assets/imgImport";

//* mui components imports
import { Fade, styled, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//* mui icons
import AccountBox from "@mui/icons-material/AccountBox";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ChatIcon from "@mui/icons-material/Chat";
import DashboardIcon from "@mui/icons-material/Dashboard";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FeedIcon from "@mui/icons-material/Feed";
import GroupIcon from "@mui/icons-material/Group";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

/* ADDED */
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
//import { NFWhiteLogoWithText } from "../../utils/imgImports";
import { NFLogoWithText } from "../../utils/imgImports";

const Offset = styled("div")(() => ({
    minHeight: 85,
}));

const flatSeekerLink = "/account/fs/";
const FLAT_SEEKER = [
    {
        id: "nf-dash-smart-dossier",
        name: "smart_dossier",
        link: `${flatSeekerLink}smart-dossier`,
        icon: <AccountBox />,
        isCompleted: <FeedIcon color="warning" />,
        title: "Complete your Dossier ",
    },
    {
        id: "nf-dash-favorite",
        name: "favorite_list",
        link: `${flatSeekerLink}favorites`,
        icon: <FavoriteIcon />,
    },
    /*
    {
        id: "nf-dash-good-matches",
        name: "good_matches",
        link: `${flatSeekerLink}good-matches`,
        icon: <HandshakeIcon />,
    },
    {
        id: "nf-dash-applied-flats-v2",
        name: "applied_flats",
        link: `${flatSeekerLink}applied-flats`,
        icon: <ListAltOutlinedIcon/>,
    },
     */
    {
        id: "nf-dash-applied-flats-v1",
        name: "applied_flats",
        link: `${flatSeekerLink}applied-flats-v1`,
        icon: <ListAltOutlinedIcon />,
    },
    {
        id: "nf-dash-new-tenant-search",
        name: "search_new_tenant",
        link: `${flatSeekerLink}flats`,
        icon: <PersonSearchIcon />,
    },

    {
        id: "nf-dash-chat",
        name: "chats",
        link: "/account/chats",
        icon: <ChatIcon />,
    },
    /*  {
        id: "nf-dash-search-subscription",
        name: "search_subscription",
        link: `${flatSeekerLink}create-alert`,
        icon: <ScreenSearchDesktopIcon/>,
    },
     */
    /*
  {
      id: "nf-dash-settings",
      name: "settings",
      link: "/account/settings",
      icon: <SettingsIcon/>,
      isCompleted: <GppMaybeIcon color="error"/>,
      title: "Complete your Profile ",
  },
  {
      id: "nf-dash-support",
      name: "support",
      link: "/account/support",
      icon: <HelpCenterIcon/>,
  },
     */
];
const newTenantLink = "/account/nt/";
const NEW_TENANT = [
    {
        id: 1,
        name: "dashboard",
        link: `${newTenantLink}dashboard`,
        icon: <DashboardIcon />,
    },
    {
        id: 2,
        name: "flat_lists",
        link: `${newTenantLink}flats`,
        icon: <HomeWorkIcon />,
    },

    { id: 4, name: "chats", link: "/account/chats", icon: <ChatIcon /> },

    /*  {
        id: 5,
        name: "settings",
        link: "/account/settings",
        icon: <SettingsIcon/>,
        isCompleted: <GppMaybeIcon color="error"/>,
        title: "Complete your Profile ",
    },
    {
        id: 6,
        name: "support",
        link: "/account/support",
        icon: <HelpCenterIcon/>,
    },
     */
];

const propertyMangerLink = "/account/pm/";
const PROPERTY_MANAGER = [
    {
        id: 1,
        name: "dashboard",
        link: `${propertyMangerLink}dashboard`,
        icon: <DashboardIcon />,
    },
    {
        id: 2,
        name: "flat_lists",
        link: `${propertyMangerLink}flats`,
        icon: <HomeWorkIcon />,
    },
    {
        id: 4,
        name: "appointment",
        link: `${propertyMangerLink}appointments`,
        icon: <CalendarMonthIcon />,
    },
    {
        id: 5,
        name: "agents",
        link: `${propertyMangerLink}agents`,
        icon: <RecordVoiceOverIcon />,
    },
    {
        id: 6,
        name: "clients",
        link: `${propertyMangerLink}clients`,
        icon: <GroupIcon />,
    },
    {
        id: 7,
        name: "nextFlat_clients",
        link: `${propertyMangerLink}nf-clients`,
        icon: <GroupIcon />,
    },

    { id: 8, name: "chats", link: "/account/chats", icon: <ChatIcon /> },
    /*  {
       id: 10,
       name: "settings",
       link: "/account/settings",
       icon: <SettingsIcon/>,
       isCompleted: <GppMaybeIcon color="error"/>,
       title: "Complete your Profile ",
    },
    {
        id: 11,
        name: "support",
        link: "/account/support",
        icon: <HelpCenterIcon/>,
    },
     */
];

const drawerWidth = 260;

const StyledMenu = styled(Menu)(({ theme }) => ({
    marginTop: "5px",
    marginBottom: "5px",
    transition: "color 300ms ease-in, background-color 300ms ease-in",
    "& .MuiPaper-root": {
        borderColor: "white",
        borderWidth: "3px",
        borderStyle: "solid",
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
    },
    "& .MuiListItemIcon-root": {
        color: theme.palette.common.white,
    },
    "& .MuiListItemText-primary": {
        color: theme.palette.common.white,
        fontWeight: 500,
    },
    "& .MuiMenuItem-root": {
        fontWeight: 500,
        color: theme.palette.common.white,
        "&.Mui-selected": {
            "& .MuiListItemText-primary": {
                color: theme.palette.secondary.main,
            },
        },
        "&.Mui-selected, &.Mui-selected:hover": {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.secondary.main,
        },
        "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            "& .MuiListItemText-primary": {
                color: theme.palette.secondary.main,
            },
        },
    },
}));

const StyledListButton = styled(ListItemButton)(({ theme }) => ({
    marginTop: "5px",
    marginBottom: "5px",
    transition: "color 300ms ease-in, background-color 300ms ease-in",
    "&.Mui-selected": {
        ".MuiListItemText-primary": {
            color: theme.palette.secondary.main,
            fontWeight: 500,
        },
        ".MuiListItemIcon-root": {
            color: theme.palette.secondary.main,
        },
    },

    "& .MuiListItemIcon-root": {
        color: theme.palette.primary.contrastText,
    },
    ".MuiListItemText-primary": {
        fontWeight: 500,
        color: theme.palette.primary.contrastText,
    },
    "&:hover:not(.Mui-selected)": {
        ".MuiListItemText-primary": {
            color: theme.palette.secondary.main,
            fontWeight: 500,
        },
        ".MuiListItemIcon-root": {
            color: theme.palette.secondary.main,
        },
    },
}));

const getRoleData = (role) => {
    if (role === "TN")
        return {
            label: "tenant",
            optionLabel: "role_label_new_tenant",
            option: FLAT_SEEKER,
        };
    if (role === "PM")
        return {
            label: "owner",
            optionLabel: "role_label_property_owner",
            option: PROPERTY_MANAGER,
        };

    return {
        label: "tenant",
        optionLabel: "role_label_new_tenant",
        option: FLAT_SEEKER,
    };
};

function Dashboard(props) {
    const { window } = props;
    //console.log(NF_ROLE);
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [{ user_id, user_role }] = useStateValue();
    const [profileRole, setProfileRole] = useState(user_role || "TN");
    const [mobileOpen, setMobileOpen] = useState(false);
    const { isLoading, data, refetch } = useUserProfile(user_id);
    let profileData = data?.data?.data[0];

    const { data: checkDossierCreated } = useCheckDossier(user_id);
    const dossierCreated = checkDossierCreated?.data?.data;

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleProfileRoles = (role) => {
        if (role === profileRole) return handleClose();
        if (role === "PM") {
            navigate(PROPERTY_MANAGER[0].link);
        } else {
            navigate(FLAT_SEEKER[0].link);
        }
        setProfileRole(role);
        handleClose();
    };

    const role = getRoleData(profileRole);

    const drawer = (
        <Box
            sx={{
                backgroundColor: theme.palette.primary.main,
            }}
        >
            <Box
                sx={{
                    minHeight: "90px",
                    display: "flex",
                    alignItems: "left",
                    justifyContent: "left",
                    flexDirection: "column",
                    position: "relative",
                    backgroundColor: "#FFFFFF",
                }}
            >
                <Link
                    to={"/"}
                    style={{ textDecoration: "none", padding: "17px" }}
                >
                    <img src={NFLogoWithText} alt="nextflat" height={44} />
                </Link>
            </Box>
            {/*
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    bgcolor: theme.palette.common.white,
                    width: "100%",
                    height: 70,
                    pt: 3,
                    py: 1,
                    pl: 2,
                    pr: 1,
                }}
            >
                <Box>
                    <Typography
                        variant="subtitle1"
                        sx={{ fontSize: "0.875rem", fontWeight: "normal" }}
                    >
                        {t("role_label_title")}
                    </Typography>
                    <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
                        {t(role.optionLabel)}
                    </Typography>
                </Box>
                <IconButton aria-label="" onClick={handleClick}>
                    <Icon>chevron_right</Icon>
                </IconButton>
            </Box>
                */}
            {/* <Divider /> */}
            <Box
                sx={{
                    height: "100vh",
                    overflowY: "hidden",
                    scrollbarWidth: "thin",
                    marginTop: "40px",
                    "&::-webkit-scrollbar": {
                        width: ".6em",
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#fff",
                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ddd",
                        borderRadius: "3rem",
                    },
                    "&:hover": {
                        overflowY: "auto",
                    },
                }}
            >
                <List>
                    {role.option.map((role) => (
                        <ListItem key={role.id} disablePadding>
                            <StyledListButton
                                onClick={() => {
                                    navigate(role.link);
                                    setMobileOpen(false);
                                }}
                                selected={pathname === role.link}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 40,
                                    }}
                                >
                                    {role.icon}
                                </ListItemIcon>
                                <ListItemText
                                    primary={t(role.name)}
                                    sx={{
                                        fontSize: ".875rem",
                                    }}
                                />
                            </StyledListButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
        </Box>
    );

    const container =
        window !== undefined ? () => window().document.body : undefined;

    return (
        <>
            <Box sx={{ display: "flex" }}>
                <DashboardHeader
                    handleDrawerToggle={handleDrawerToggle}
                    role={role.label}
                />
                <Box
                    component="nav"
                    sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
                >
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: "block", md: "none" },
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                overflow: "hidden",
                            },
                        }}
                    >
                        {drawer}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: "none", md: "block" },
                            "& .MuiDrawer-paper": {
                                width: drawerWidth,
                                overflow: "hidden",
                            },
                        }}
                        open
                    >
                        {drawer}
                    </Drawer>
                </Box>
                <Box
                    component="main"
                    sx={{
                        flexGrow: 1,
                        pt: 3,
                        width: { sm: `calc(100% - ${drawerWidth}px)` },
                        backgroundColor: "#FAFAFB",
                        minHeight: "100vh",
                    }}
                >
                    <Offset />
                    {isLoading ? (
                        <Loading />
                    ) : (
                        <Outlet
                            context={{
                                ...profileData,
                                refetch,
                                dossierCreated,
                            }}
                        />
                    )}
                </Box>
            </Box>

            <StyledMenu
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
                transformOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
                anchorOrigin={{
                    horizontal: "left",
                    vertical: "bottom",
                }}
            >
                <MenuItem
                    selected={profileRole === "TN"}
                    onClick={() => handleProfileRoles("TN")}
                >
                    <ListItemIcon>
                        <SearchIcon />
                    </ListItemIcon>
                    <ListItemText>{t("role_label_tenant")}</ListItemText>
                </MenuItem>
                <MenuItem
                    selected={profileRole === "PM"}
                    onClick={() => handleProfileRoles("PM")}
                >
                    <ListItemIcon>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText>
                        {t("role_label_property_owner")}
                    </ListItemText>
                </MenuItem>
            </StyledMenu>
        </>
    );
}

export default Dashboard;
