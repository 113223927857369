import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { Link } from "react-router-dom";

import { NFLogoWithText, NMLogoWithText } from "../../utils/imgImports";
import useResponsive from "../../hooks/responsive/useResponsive";

import NavigationLinks from "./NavigationLinks";
import NavigationButtons from "./NavigationButtons";

function MobileDrawer({ open, handleClose }) {
    const { isBelowMd } = useResponsive();

    return (
        <Drawer
            open={open}
            onClose={handleClose}
            anchor="right"
            PaperProps={{
                sx: { width: { xs: "100%", md: "50%" }, px: "25px" },
            }}
        >
            <Stack rowGap={9}>
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    pt={7.5}
                >
                    <Link to={"/"} onClick={handleClose}>
                        <img
                            src={NFLogoWithText}
                            width={137}
                            height={65}
                            loading={"eager"}
                            alt="nextflat"
                        />
                    </Link>
                    <IconButton onClick={handleClose}>
                        <Icon style={{ fontSize: 50 }}>close</Icon>
                    </IconButton>
                </Stack>
                <Stack rowGap={4}>
                    <NavigationLinks handleDrawerClose={handleClose} />
                </Stack>
                {isBelowMd && (
                    <Stack alignItems={"flex-start"} rowGap={2.5}>
                        <NavigationButtons handleDrawerClose={handleClose} />
                    </Stack>
                )}
            </Stack>
        </Drawer>
    );
}

MobileDrawer.propTypes = {
    handleClose: PropTypes.func,
    open: PropTypes.bool,
};

export default MobileDrawer;
