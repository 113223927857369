import React from "react";

import { useTranslation } from "react-i18next";

//* responsive api
import useResponsive from "../../hooks/responsive/useResponsive";

//* mui components
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* mui icons
import CloseIcon from "@mui/icons-material/Close";

function AboutSponsor({ open, onClose }) {
   const { isBelowSm } = useResponsive();
   const { t } = useTranslation();

   return (
      <Dialog
         fullScreen={isBelowSm}
         open={open}
         onClose={onClose}
         scroll='paper'
         fullWidth
         maxWidth='md'
         PaperProps={{
            sx: {
               borderRadius: 5,
            },
         }}
      >
         <Stack flexDirection={"row"} justifyContent={"flex-end"} p={2}>
            <CloseIcon
               onClick={onClose}
               fontSize='large'
               sx={{
                  cursor: "pointer",
                  marginLeft: 1,
               }}
            />
         </Stack>

         <Stack
            rowGap={2.5}
            py={{
               xs: 1,
               md: 3,
            }}
            px={{
               xs: 2,
               md: 12.5,
            }}
         >
            <Stack rowGap={2.5}>
               <Typography variant='h2'>
                  {t("how_does_free_sponsored_work")}
               </Typography>
               <Typography variant='body1'>
                  {t("working_of_free_sponsored")}
               </Typography>
            </Stack>
            <Stack rowGap={2.5}>
               <Typography variant='h3'>
                  {t(
                     "your_choice_of_partner_companies_is_free_of_charge_and_without_obligation"
                  )}
               </Typography>
               <Typography variant='body1'>
                  {t(
                     "you_have_the_freedom_to_choose_partner_companies_according_to_your_own_preferences"
                  )}
               </Typography>
            </Stack>
         </Stack>
      </Dialog>
   );
}

export default AboutSponsor;
