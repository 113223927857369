import React, { useEffect, useState } from "react";

//* libraries
import { useTranslation } from "react-i18next";

//* libraries
import { createSearchParams, useNavigate } from "react-router-dom";

//* api calls

//* store imports
import { useStateValue } from "../../store/StateProvider";

//* api calls
import useGetAllProperties from "../../hooks/properties/useProperties";

//* components import
import PropertyCard from "../../component/cards/PropertyCard";
import NoDataFound from "../no-data/NoDataFound";

//* mui components imports
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

//* Animation

import { useTheme } from "@mui/material";
import FeaturedPropertySkeleton from "../skeleton/FeaturedPropertySkeleton";

function LatestPropertySection() {
    const { t } = useTranslation();
    const [{ user_id }] = useStateValue();
    const [properties, setProperties] = useState(null);
    const navigate = useNavigate();
    const [limit, setLimit] = useState(8);
    const theme = useTheme();

    const {
        isLoading: propertiesLoading,
        data: allProperties,
        status,
    } = useGetAllProperties({
        page: 0,
        limit: limit,
        lang: "en",
        city_id: "",
        type_id: "",
        category_id: "",
        floor: "",
        space_from: "",
        space_to: "",
        number_bedroom: "",
        price: "",
        aminity: "",
        availability: "",
        user_id,
    });

    useEffect(() => {
        if (status === "success" && allProperties?.data?.results) {
            const numProperties = allProperties.data.totalItems;
            if (numProperties < 9) {
                const newLimit = Math.floor(numProperties / 4) * 4;
                setLimit(newLimit > 0 ? newLimit : 4);
            }
            setProperties(allProperties.data.results);
        }
    }, [status, allProperties]);

    const onViewMore = () => {
        let viewMoreOptions = {
            lang: "",
            city_id: "",
            type_id: 2,
            category_id: "",
            floor: "",
            period: "",
            space_from: "",
            space_to: "",
            number_bedroom: "",
            price: "",
            aminity: "",
            user_id: "",
        };
        navigate({
            pathname: "/explore-list",
            search: `?${createSearchParams(viewMoreOptions)}`,
        });
    };

    const loadingProperties = () => {
        return Array.from({ length: 8 }, (_, index) => (
            <Grid key={index} item xs={12} sm={12} md={3} lg={3}>
                <FeaturedPropertySkeleton />
            </Grid>
        ));
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        color: theme.palette.primary.main,
                        mt: 0.5,
                        mr: 1,
                    }}
                >
                    {t("listing_property")}
                </Typography>
                <Typography component="p" pt={3}>
                    {t("find_better_places_to_live_work_and_relax")}
                </Typography>
            </Box>
            <Grid
                container
                // variants={PropertyCardAnimation}
                // initial='hidden'
                // animate={controls}
                rowGap={2}
                mt={5}
            >
                {propertiesLoading ? (
                    loadingProperties()
                ) : Array.isArray(properties) && properties.length <= 0 ? (
                    <Stack
                        justifyContent={"center"}
                        alignItems={"center"}
                        width={"100%"}
                        height={"100%"}
                    >
                        <NoDataFound />
                        <Typography variant="h4" color="primary">
                            {t("no_data_found")}
                        </Typography>
                    </Stack>
                ) : (
                    properties?.map((property) => {
                        return (
                            <Grid
                                // variants={PropertyCardAnimationItem}
                                item
                                key={property?.id}
                                xs={12}
                                sm={6}
                                md={4}
                                lg={3}
                                xl={3}
                            >
                                <PropertyCard {...property} />
                            </Grid>
                        );
                    })
                )}
            </Grid>
            <Stack direction="row" justifyContent={"center"} pt={5}>
                <Button variant="contained" size="large" onClick={onViewMore}>
                    {t("view_all_properties")}
                </Button>
            </Stack>
        </Box>
    );
}

export default LatestPropertySection;
