import React, { Fragment, useState } from "react";
import Grid from "@mui/material/Grid";
import {
    ControllerTextField,
    ControllerDateField,
    ControllerAutoCompleteField,
    ControllerPhoneTextField,
    TitleSpacer,
    FieldHintSpacer,
    ControllerSelectField,
    ControllerNumberField,
    ControllerRadioField,
} from "../../../../../mui-components/MuiDossierFormComponents";
import DossierFormSection from "../../../dossier_components/DossierFormSection";

import { useContext } from "react";
import FormSectionContextSegmentN, {
    useCommonEditFormContext,
} from "../../tenant_common_context/EditFormSectionContextSegment";
import FormControl from "@mui/material/FormControl";
import { Controller } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox, FormGroup } from "@mui/material";
import {
    interestedPartnerArr,
    isYesNoOption,
    movingOutOption,
    numberArray,
    SmokeOptionArr,
    vehicles,
} from "../../../../../../options/dossierOptions";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import { DossierReferenceBox } from "../../../dossier_components/DossierReferenceBox";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import ArrowDropDownCircleOutlinedIcon from "@mui/icons-material/ArrowDropDownCircleOutlined";
import Radio from "@mui/material/Radio";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ControllerToggleButtonsComponent from "../../../dossier_components/ControllerToggleButtonsComponent";

const TenantOneSegmentSix = ({ additionalProps }) => {
    const commonProps = useCommonEditFormContext();

    const { t } = commonProps;

    const {
        heading,
        Icon,
        index,
        watchChildren,
        handleNoOfChildren,
        watchNoOfChildren,
        watchChildrensAverageAge,
        watchSmoker,
        watchMusicalInstrument,
        watchVehicle,
        watchTypeOfVehicle,
        watchIsParkingNeeded,
        watchPets,
        partnerRef,
        watchAnotherTenantMovingIn,
        control,
        errors,
        watch,
        sectionVariants,
        direction,
    } = additionalProps;

    return (
        <DossierFormSection {...commonProps} {...additionalProps}>
            <Grid item container spacing={4}>
                <Grid item xs={12} sm={12} md={4} lg={4} xl={4} sx={{ mt: 4 }}>
                    <ControllerNumberField
                        control={control}
                        label={t("how_many_adults_will_be_moving_in") + "?"}
                        name="noOfAdults"
                        min={0}
                        max={15}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                    <ControllerToggleButtonsComponent
                        control={control}
                        name="hasChildren"
                        label={t("do_you_have_children")}
                        data={isYesNoOption}
                    />
                    {/*
                    <ControllerRadioField
                        control={control}
                        name="hasChildren"
                        label={`${t("do_you_have_children")} ?`}
                    >
                        <FormControlLabel
                            label={t("no")}
                            value="no"
                            control={<Radio />}
                        />
                        <FormControlLabel
                            label={t("yes")}
                            value="yes"
                            control={<Radio />}
                        />
                    </ControllerRadioField>
                      */}
                </Grid>
                {watchChildren === "yes" && (
                    <>
                        <Grid
                            item
                            xs={12}
                            sm={4}
                            md={6}
                            lg={6}
                            xl={6}
                            sx={{ mt: 4 }}
                        >
                            <Controller
                                control={control}
                                name="noOfChildren"
                                render={({ field }) => {
                                    return (
                                        <TextField
                                            fullWidth
                                            {...field}
                                            variant="filled"
                                            label={t("how_many") + "?*"}
                                            onChange={(e) => {
                                                field.onChange(e.target.value);
                                                handleNoOfChildren(e);
                                            }}
                                            error={!!errors?.noOfChildren}
                                            helperText={t(
                                                errors?.noOfChildren?.message
                                            )}
                                            type="number"
                                            onWheel={(e) => e.target.blur()}
                                            inputProps={{
                                                min: 0,
                                                max: 8,
                                            }}
                                        />
                                    );
                                }}
                            />
                        </Grid>
                        <Grid item container spacing={2}>
                            <Grid item xs={12}>
                                <Typography
                                    component={"label"}
                                    color={"secondary"}
                                    fontWeight={500}
                                    // mb={1}
                                >
                                    {t("age_of_your_children") + "?*"}
                                </Typography>
                            </Grid>

                            {watchNoOfChildren !== "" &&
                                Array.isArray(watchChildrensAverageAge) &&
                                watchChildrensAverageAge.length > 0 &&
                                watchChildrensAverageAge?.map(
                                    (averageAge, index) => (
                                        <Grid
                                            item
                                            xs={6}
                                            sm={6}
                                            md={2}
                                            key={index}
                                        >
                                            <Controller
                                                control={control}
                                                name={`childrensAverageAge.${index}.age`}
                                                render={({ field }) => {
                                                    return (
                                                        <TextField
                                                            label={`${
                                                                index + 1
                                                            }. ${t("child")}*`}
                                                            {...field}
                                                            variant="filled"
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        {t(
                                                                            "yrs"
                                                                        )}
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            error={
                                                                !!errors?.childrensAverageAge
                                                            }
                                                            helperText={
                                                                !!errors?.childrensAverageAge
                                                                    ? t(
                                                                          errors
                                                                              ?.childrensAverageAge[
                                                                              index
                                                                          ]?.age
                                                                              ?.message
                                                                      )
                                                                    : ""
                                                            }
                                                        />
                                                    );
                                                }}
                                            />
                                        </Grid>
                                    )
                                )}
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Typography
                                component={"label"}
                                color={"secondary"}
                                fontWeight={500}
                                // mb={1}
                            >
                                {t("How_many_children_are_moving_in_with_you")}
                            </Typography>
                            <ControllerSelectField
                                control={control}
                                name="noOfChildrenMovingIn"
                                label={t("no_of_children")}
                                errorFlag={!!errors?.noOfChildrenMovingIn}
                                errorText={t(
                                    errors?.noOfChildrenMovingIn?.message
                                )}
                            >
                                {numberArray(8)?.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </ControllerSelectField>
                        </Grid>
                    </>
                )}

                <Grid item container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="isSmoker"
                            label={t("do_you_smoke")}
                            data={isYesNoOption}
                        />

                        {/*

                        <ControllerRadioField
                            control={control}
                            name="isSmoker"
                            label={t("do_you_smoke") + "?"}
                        >
                            <FormControlLabel
                                label={t("no")}
                                value="no"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                label={t("yes")}
                                value="yes"
                                control={<Radio />}
                            />
                        </ControllerRadioField>
                          */}
                    </Grid>
                    {watchSmoker === "yes" && (
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="smokePreference"
                                label={t("smoke_preference")}
                                data={SmokeOptionArr}
                                errorFlag={!!errors?.smokePreference}
                                errorText={t(errors?.smokePreference?.message)}
                            />

                            {/*

                            <ControllerSelectField
                                control={control}
                                name="smokePreference"
                                label={t("smoke_preference")}
                                errorFlag={
                                    !!errors?.smokePreference
                                }
                                errorText={t(
                                    errors?.smokePreference?.message
                                )}
                            >
                                {SmokeOptionArr?.map((option) => (
                                    <MenuItem
                                        key={option.value}
                                        value={option.value}
                                    >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </ControllerSelectField>
                               */}
                        </Grid>
                    )}
                </Grid>

                <Grid item container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="hasMusicalInstrument"
                            label={t("do_you_play_music_instruments")}
                            data={isYesNoOption}
                        />
                        {/*
                            <ControllerRadioField
                                control={control}
                                name="hasMusicalInstrument"
                                label={
                                    t("do_you_play_music_instruments") +
                                    "?"
                                }
                            >
                                <FormControlLabel
                                    label={t("no")}
                                    value="no"
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    label={t("yes")}
                                    value="yes"
                                    control={<Radio />}
                                />
                            </ControllerRadioField>
                                 */}
                    </Grid>
                    {watchMusicalInstrument === "yes" && (
                        <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="isPlayingAtHome"
                                label={t("do_you_play_at_home")}
                                data={isYesNoOption}
                                errorFlag={!!errors?.isPlayingAtHome}
                                errorText={t(errors?.isPlayingAtHome?.message)}
                            />
                            {/*
                                <ControllerSelectField
                                    control={control}
                                    name="isPlayingAtHome"
                                    label={
                                        t("do_you_play_at_home") + "?*"
                                    }
                                    errorFlag={
                                        !!errors?.isPlayingAtHome
                                    }
                                    errorText={t(
                                        errors?.isPlayingAtHome?.message
                                    )}
                                >
                                    {isYesNoOption?.map((option) => (
                                        <MenuItem
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {t(option.label)}
                                        </MenuItem>
                                    ))}
                                </ControllerSelectField>
                                      */}
                        </Grid>
                    )}
                </Grid>

                <Grid item container>
                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="hasVehicle"
                            label={t("do_you_have_vehicles")}
                            data={isYesNoOption}
                        />
                        {/*
                        <ControllerRadioField
                            control={control}
                            name="hasVehicle"
                            label={t("do_you_have_vehicles") + "?"}
                        >
                            <FormControlLabel
                                label={t("no")}
                                value="no"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                label={t("yes")}
                                value="yes"
                                control={<Radio />}
                            />
                        </ControllerRadioField>
                          */}
                    </Grid>

                    {watchVehicle === "yes" && (
                        <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="typeOfVehicle"
                                label={t("type_of_vehicle")}
                                data={vehicles}
                                errorFlag={!!errors?.typeOfVehicle}
                                errorText={t(errors?.typeOfVehicle?.message)}
                            />
                            {/*

                            <ControllerSelectField
                                control={control}
                                name="typeOfVehicle"
                                label={t("type_of_vehicle")}
                                errorFlag={!!errors?.typeOfVehicle}
                                errorText={t(
                                    errors?.typeOfVehicle?.message
                                )}
                            >
                                {vehicles.map((option) => (
                                    <MenuItem
                                        key={option.id}
                                        value={option.value}
                                    >
                                        {t(option.label)}
                                    </MenuItem>
                                ))}
                            </ControllerSelectField>
                                */}
                        </Grid>
                    )}
                    {watchTypeOfVehicle !== "" && watchVehicle === "yes" && (
                        <Grid item pl={2} xs={12} sm={6} md={4} lg={4} xl={4}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="isParkingNeeded"
                                label={t("need_parking_space")}
                                data={isYesNoOption}
                                errorFlag={!!errors?.isParkingNeeded}
                                errorText={t(errors?.isParkingNeeded?.message)}
                            />
                            {/*
                                <ControllerRadioField
                                    control={control}
                                    name="isParkingNeeded"
                                    label={
                                        t("need_parking_space") +
                                        "?*"
                                    }
                                    errorFlag={
                                        !!errors?.isParkingNeeded
                                    }
                                    errorText={t(
                                        errors?.isParkingNeeded
                                            ?.message
                                    )}
                                >
                                    <FormControlLabel
                                        label={t("no")}
                                        value="no"
                                        control={<Radio />}
                                    />
                                    <FormControlLabel
                                        label={t("yes")}
                                        value="yes"
                                        control={<Radio />}
                                    />
                                </ControllerRadioField>
                                     */}
                            {watchIsParkingNeeded === "yes" && (
                                <ControllerTextField
                                    control={control}
                                    name="numberOfParkingSpace"
                                    label={t("no_of_parking_space")}
                                    errorFlag={!!errors?.numberOfParkingSpace}
                                    errorText={t(
                                        errors?.numberOfParkingSpace?.message
                                    )}
                                />
                            )}
                        </Grid>
                    )}
                </Grid>

                <Grid item container>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="hasPet"
                            label={t("do_you_have_pets")}
                            data={isYesNoOption}
                        />
                        {/*
                        <ControllerRadioField
                            control={control}
                            name="hasPet"
                            label={t("do_you_have_pets") + "?"}
                        >
                            <FormControlLabel
                                label={t("no")}
                                value="no"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                label={t("yes")}
                                value="yes"
                                control={<Radio />}
                            />
                        </ControllerRadioField>
                        */}
                    </Grid>
                    {watchPets === "yes" && (
                        <Fragment>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <ControllerTextField
                                    name={"pet"}
                                    control={control}
                                    label={
                                        t("what_pets_(with_size_for_each)") +
                                        "?*"
                                    }
                                    errorFlag={!!errors?.pet}
                                    errorText={
                                        <Fragment>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                alignItems={"center"}
                                            >
                                                <InfoOutlinedIcon color="secondary" />
                                                <Typography variant="subtitle2">
                                                    {t(
                                                        "please_enter_the_type_and_height_of_each_pet"
                                                    )}
                                                </Typography>
                                            </Stack>
                                            <br />
                                            {!!errors?.pet &&
                                                t(errors?.pet?.message)}
                                        </Fragment>
                                    }
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>

                <Grid item container>
                    <Grid
                        ref={partnerRef}
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        lg={6}
                        xl={6}
                    >
                        <ControllerToggleButtonsComponent
                            control={control}
                            name="isAnotherTenantMovingIn"
                            label={t("is_another_tenant_moving_in") + "?"}
                            data={isYesNoOption}
                        />
                        {/*

                        <ControllerRadioField
                            control={control}
                            name="isAnotherTenantMovingIn"
                            label={
                                t("is_another_tenant_moving_in") +
                                "?"
                            }
                        >
                            <FormControlLabel
                                label={t("no")}
                                value="no"
                                control={<Radio />}
                            />
                            <FormControlLabel
                                label={t("yes")}
                                value="yes"
                                control={<Radio />}
                            />
                        </ControllerRadioField>
                            */}
                        {watchAnotherTenantMovingIn === "yes" && (
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems={"center"}
                            >
                                <InfoOutlinedIcon
                                    sx={{
                                        color: (theme) =>
                                            theme.palette.secondary.main,
                                    }}
                                />
                                <Typography variant="subtitle">
                                    {t(
                                        "on_the_next_page_please_fill_out_the_form"
                                    )}
                                </Typography>
                            </Stack>
                        )}
                    </Grid>
                    {watchAnotherTenantMovingIn === "yes" && (
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                            <ControllerToggleButtonsComponent
                                control={control}
                                name="anotherTenantRelation"
                                label={
                                    t(
                                        "what_is_your_relationship_to_each_other"
                                    )
                                }
                                data={interestedPartnerArr}
                                errorFlag={!!errors?.anotherTenantRelation}
                                errorText={t(
                                    errors?.anotherTenantRelation?.message
                                )}
                            />
                            {/*

                            <ControllerRadioField
                                name={"anotherTenantRelation"}
                                label={
                                    t(
                                        "what_is_your_relationship_to_each_other"
                                    ) + "?*"
                                }
                                control={control}
                                errorFlag={
                                    !!errors?.anotherTenantRelation
                                }
                                errorText={t(
                                    errors?.anotherTenantRelation
                                        ?.message
                                )}
                            >
                                {interestedPartnerArr.map(
                                    (interested) => (
                                        <FormControlLabel
                                            key={interested.id}
                                            label={t(
                                                interested.label
                                            )}
                                            value={interested.value}
                                            control={<Radio />}
                                        />
                                    )
                                )}
                            </ControllerRadioField>
                              */}
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </DossierFormSection>
    );
};

export default TenantOneSegmentSix;
